import React, { FC } from "react";
import styled from "styled-components";

import { RouteComponentProps } from "@reach/router";

import { PublicRoute } from "../components/PublicRoute";
import { SEO } from "../components/Seo";
import { H1, H4, H5, Typography as T } from "../components/Typography";
import { Anchor } from "../components/Anchor";

const Typography = styled(T)`
  line-height: 26px;
  margin-bottom: 1rem;
`;

const PrivacyPolicy: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Privacy Policy" />
      <div className="flex flex-1 w-full flex-col max-w-screen-xl mx-auto">
        <H1 className="mb-12" style={{ fontSize: 54, marginTop: "12rem" }}>
          Privacy Policy
        </H1>

        <H5 className="mb-8">Colorline is committed to protecting your data and privacy.</H5>

        <H4 className="mb-8">PROCESSING OF PERSONAL DATA</H4>

        <Typography>
          Personal information that is transferred to Colorline in the United States from the EU or Switzerland falls
          into the following two categories:
        </Typography>

        <Typography>
          Processor on Behalf – Colorline provides document solutions designed to help organizations optimize their
          document creation and maintenance operations. In the course of doing business, Colorline receives documents
          for the purpose of providing document review solutions. Colorline does not own or control any of the
          information it processes on behalf of its customers or prospective customers. All such information is owned
          and controlled by the Colorline customer. In this capacity, Colorline receives information transferred from
          the EU or Switzerland to the United States merely as a processor on behalf. When acting as a Processor on
          Behalf, concerning personal information that has been transferred from the EU or Switzerland to the United
          States, Colorline has in place measures to protect personal information from loss, misuse, unauthorized
          access, disclosure, alteration and destruction.
        </Typography>

        <Typography>
          Personal Data Collection – Colorline collects information about its customers and prospective customers for
          the purpose of facilitating business relationships, correspondence and communication. In this business
          context, Colorline may provide access to product information, educational webinars, marketing updates and
          industry information. It is through this regular communication that Colorline is able to provide valuable
          information about important issues related to our customers’ key work product—their documents. As a general
          practice, name, and business information including address, phone number, email address and employment
          information are the only personal information items collected.
        </Typography>

        <H4 className="mb-8">INFORMATION COLLECTION</H4>

        <Typography>
          We may collect personally identifiable information such as your name, email address, or phone number. We may
          also collect non-personal information (which cannot be used to personally identify you), such as how and when
          the Website or Services are accessed. Also, as part of using our Services, you may send us data such as
          documents and files (“User Content”) for the purpose of using the functionality of the Services, such as
          document analysis. Together, the personally identifiable information, non-personal information and User
          Content are referred to herein as “Information.”
        </Typography>

        <H4 className="mb-8">COOKIES</H4>

        <Typography>
          Colorline may use browser cookies on this website to keep track of your visit. A “cookie” is a small amount of
          data that is sent from a Web server to your browser and stored on your computer’s hard drive. Use of cookies
          may allow Colorline to provide a higher quality, more relevant, customer experience. Use of cookies may also
          allow us to automate entry into password protected portions of our website so that you will not need to
          re-enter your password each time you visit our Site. We may also use cookies to track which areas of our
          website are most popular with our users. With most Internet browsers, however, you can erase cookies from your
          computer hard drive, block all cookies, or receive a warning before a cookie is stored on your computer.
          Please refer to your browser instructions or a help screen to learn more about these functions and how to use
          them. Please be advised that certain sections or functionalities of our website may be inaccessible to you if
          your browser does not accept cookies.
        </Typography>

        <H4 className="mb-8">COOKIES</H4>

        <Typography>
          Colorline may use browser cookies on this website to keep track of your visit. A “cookie” is a small amount of
          data that is sent from a Web server to your browser and stored on your computer’s hard drive. Use of cookies
          may allow Colorline to provide a higher quality, more relevant, customer experience. Use of cookies may also
          allow us to automate entry into password protected portions of our website so that you will not need to
          re-enter your password each time you visit our Site. We may also use cookies to track which areas of our
          website are most popular with our users. With most Internet browsers, however, you can erase cookies from your
          computer hard drive, block all cookies, or receive a warning before a cookie is stored on your computer.
          Please refer to your browser instructions or a help screen to learn more about these functions and how to use
          them. Please be advised that certain sections or functionalities of our website may be inaccessible to you if
          your browser does not accept cookies.
        </Typography>

        <H4 className="mb-8">USE OF WEB BEACONS</H4>

        <Typography>
          When we send you emails, we may include a web beacon to allow us to determine the number of people who open
          our emails. When you click on a link in an email, we may record this individual response to allow us to
          customize our offerings to you. Web beacons collect only limited information, such as a cookie identifier,
          time and date of a page being viewed, and a description of the page on which the web beacon resides (the URL).
        </Typography>

        <Typography>
          Web beacons can be refused when delivered via email. If you do not wish to allow web beacons via email, you
          will need to disable HTML images or refuse HTML (select Text only) emails via your email software.
        </Typography>

        <H4 className="mb-8">LINKS</H4>

        <Typography>
          The Website contains links to external sites, including our partners’ websites and others. These websites are
          not governed by this Privacy Policy, and we do not take responsibility for the privacy practices of any
          third-party websites to which we link.
        </Typography>

        <H4 className="mb-8">PURPOSE & USAGE OF INFORMATION</H4>

        <Typography>
          We will never share, sell, or rent Information with anyone without your advance permission or unless ordered
          by a court of law. We only use Information for the purpose of delivering the Services (such as
          troubleshooting, promoting the safe exchange of documents, collecting fees and authenticating users), and the
          Information is only available to employees and authorized business partners for that purpose. If we are
          involved in a merger, reorganization, or sale of our business, the Information will be transferred as part of
          that deal. We will notify you of any such deal and outline your choices.
        </Typography>

        <H4 className="mb-8">CHOICE</H4>

        <Typography>
          Colorline will provide an individual with opt-out or opt-in choices before we share their data with third
          parties other than our agents or business partners, or before we use it for purposes other than which it was
          originally collected or subsequently authorized. Colorline, upon request, will remove an individual’s name and
          related information from all communication lists. Individuals should direct such a request to Colorline
          Customer Support by sending an email to us at{" "}
          <Anchor href="mailto:contact@colorline.io">contact@colorline.io</Anchor>.
        </Typography>

        <H4 className="mb-8">ACCOUNTABILITY FOR ONWARD TRANSFERS</H4>

        <Typography>
          Colorline takes steps to ensure that all agents who perform processing tasks on behalf of and under the
          instruction of Colorline provide the same level of protection as Colorline. Individuals that would like to
          limit the use and disclosure of their personal data should direct such a request to Colorline Customer Support
          by sending an email to us at <Anchor href="mailto:contact@colorline.io">contact@colorline.io</Anchor>.
        </Typography>

        <H4 className="mb-8">COMPLIANCE WITH LAW</H4>

        <Typography>
          Colorline may be required to disclose an individual’s personal information in response to a lawful request by
          public authorities, including to meet national security or law enforcement requirements. Colorline complies
          with all applicable privacy laws and regulations. We may be compelled to surrender personal user or customer
          information to United States or other legal authorities without expressed user consent if presented with a
          court subpoena or similar legal order, or as required or permitted by the laws, rules and regulations of any
          nation, state or other applicable jurisdiction. In the event of a violation of the terms and conditions of
          usage of this website or a violation of any restrictions on usage of materials provided in or through this
          website, we may disclose personal user information to our affected business partners or legal authorities.
        </Typography>

        <H4 className="mb-8">SECURITY</H4>

        <Typography>
          Colorline may use technological and operational security processes designed to protect Information from loss,
          misuse, alteration or destruction. Only authorized employees have access to Information provided by you, and
          that access is limited by need. Each employee having access to any Information is obligated to maintain
          confidentiality. Although we take steps that are generally accepted as industry standard to protect your
          personally-identifiable information, we cannot guarantee that your personally-identifiable information will
          not become accessible to unauthorized persons.
        </Typography>

        <H4 className="mb-8">TRANSFER</H4>

        <Typography>
          Customers and prospective customers may send data to Colorline via regular email, TLS-encrypted email, or by
          upload to our website.
        </Typography>

        <H4 className="mb-8">DELETION</H4>

        <Typography>
          As part of their relationship with Colorline, Colorline customers can create an account and upload documents
          on the Colorline website. The website is currently based in the US. All customer and prospective customer
          documents transmitted via the website are deleted within 90 days after the project has been completed or after
          the associated issue has been closed, and is not needed to validate future releases. Documents transmitted to
          Colorline via email will be processed consistently with other email communication and, as such, will be part
          of Colorline’s normal business continuity procedures, including off-site backup.
        </Typography>

        <H4 className="mb-8">DATA INTEGRITY AND PURPOSE LIMITATIONS</H4>

        <Typography>
          Personal information collected must be relevant for the purposes for which it will be used. To the extent
          necessary for those purposes, Colorline will take reasonable steps to ensure that personal information is
          accurate, complete, current and reliable for its intended use.
        </Typography>

        <H4 className="mb-8">ACCESS</H4>

        <Typography>
          Colorline acknowledges that individuals have the right to access the personal information that we maintain
          about them. Further, Colorline will allow the individual to correct, update, or delete information.
          Individuals who wish to make an access request or remove personal information from our records or have any
          questions in regard to this policy or believe that Colorline has not complied with the provisions of this
          policy, should direct such a request to Colorline Customer Support by sending an email to us at{" "}
          <Anchor href="mailto:contact@colorline.io">contact@colorline.io</Anchor>.
        </Typography>

        <H4 className="mb-8">RECOURSE, ENFORCEMENT & LIABILITY</H4>

        <Typography>
          Colorline uses a self-assessment approach to ensure compliance with this policy. This includes a periodic
          assessment to assure the policy is accurate and adhered to throughout the organization. If an individual has
          concerns, please send an email to contact@colorline.io to raise those concerns and Colorline will investigate
          and attempt to resolve any complaints and disputes regarding use and disclosure of personal information in
          accordance with the Principles. Filing a complaint in English will expedite the process.
        </Typography>

        <Typography>
          Colorline commits to resolve complaints about your privacy and our collection or use of your personal
          information. Inquires or complaints regarding this privacy policy should first contact Colorline at
          <Anchor href="mailto:contact@colorline.io">contact@colorline.io</Anchor>.
        </Typography>
      </div>
    </PublicRoute>
  );
};

export default PrivacyPolicy;
